import React from "react"
import { graphql } from "gatsby"
import Collection from "../components/collection"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
query ($collection: String!) {
    allMarkdownRemark(filter: {fields: { collection: { eq: $collection }}}) {
      edges {
        node {
          id
          fields {
              slug
          }
          frontmatter{
            title,
            subtitle,
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 530, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }  
`

const capitalize = (str) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
};

const CollectionPage = ({ data, pageContext }) => {
    var items = data.allMarkdownRemark.edges.map(({node: page}) => {
      return {
        key: page.fields.slug,
        title: page.frontmatter.title,
        subtitle: page.frontmatter.subtitle,
        url: page.fields.slug, 
        featuredImage: page.frontmatter.featuredImage
    }});

    return (
        <Layout>
            <SEO title={capitalize(pageContext.collection)} />
            <div className="container">
              <Collection sectionTitle={pageContext.collection} items={items} columnsCount="4" mobileColumnsCount="2" hideSubtitle="false"></Collection>
            </div>
            
        </Layout>
)}

export default CollectionPage
